<script>
import TablePage from '../../../../../../components/table_page';
import configs from './data';

export default {
  extends: TablePage,
  props: {
    data: Array,
    value: Array,
    selectRole: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      params: {
        enableStatus: '009',
      },
      configs,
    };
  },
  created() {
    this.getConfigList('position_level_role', true, true);
  },
  methods: {
    afterCheckboxChange() {
      this.$emit('selectRole', this.selectRow);
    },
    afterCheckAllChange(val) {
      this.$emit('selectRole', val.selection);
    },
    afterGetList() {
      this.selection = this.selectRole || [];
    },
  },
};
</script>
<style lang="less" scoped>
   /deep/ .el-pagination__editor.el-input {
     display: inline-block !important;
   }
</style>
