<template>
  <div class="preview">
    <div class="preview-title">基本信息</div>
    <div class="custom-row">
      <div class="custom-col">
        <span class="custom-label">职位级别编码:</span>
        <span>{{ formConfig.positionLevelCode }}</span>
      </div>
      <div class="custom-col">
        <span class="custom-label">职位级别名称:</span>
        <span>{{ formConfig.positionLevelName }}</span>
      </div>
      <div class="custom-col">
        <span class="custom-label">默认角色:</span>
        <span>{{ formConfig.roleName }}</span>
      </div>
    </div>
    <div class="preview-title">职位信息</div>
    <TableForm :positionLevelCode="formConfig.positionLevelCode" />

    <div class="dialog-footer">
      <el-button
        type="danger"
        @click="$emit('onClose')"
        size="small"
        icon="el-icon-close"
      >关闭
      </el-button>
    </div>

  </div>
</template>

<script>
import TableForm from './table.vue';

export default {
  components: {
    TableForm,
  },
  props: ['formConfig'],
  created() {},
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.preview {
  &-title {
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .custom-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .custom-col {
      width: 50%;
      margin-bottom: 10px;
      font-size: 14px;
      color: #999;
      display: flex;

      .custom-label {
        min-width: 80px;
        margin-right: 10px;
      }
    }
  }
}
</style>
