<template>
  <div @click="inputClick">
    <el-input v-model="analysisValue"
              :disabled="disabled"
              placeholder="请输入内容">
      <i class="el-icon-search el-input__icon"
         slot="suffix"
         @click="inputClick"> </i>
    </el-input>
    <Modal :modalConfig="{title: '默认角色',visible: dialogVisible,showFooter: true}"
           @onClose="closeModal">
      <el-form>
        <el-form-item>
          <SelectRoleTable @selectRole="selectRole"
                           :selectRole="analysisSelectRole" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button type="primary"
                   @click="() => submitData()">确定</el-button>
        <el-button @click="() => closeModal()">取消</el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import SelectRoleTable from './selectRoleTable.vue';
import request from '../../../../../../utils/request';
import Modal from '../../../../../../components/modal/components/modal.vue';

export default {
  components: {
    SelectRoleTable,
    Modal,
  },
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      input: '',
      data: [],
      dialogVisible: false,
      selectRoleList: [],
      displayName: '',
    };
  },
  computed: {
    analysisValue() {
      if (Array.isArray(this.value)) {
        let strArr = [];
        strArr = this.value.map((item) => item.roleName);
        return strArr.join(',');
      }
      const selectRoleCode = this.value ? this.value.split(',') : [];
      const strArr = selectRoleCode.map((item) => this.data.find((v) => v.roleCode === item));
      return strArr.map((item) => item && item.roleName).join(',');
    },
    analysisSelectRole() {
      if (Array.isArray(this.value)) {
        return this.value.map((item) => item.roleCode);
      }
      return this.value ? this.value.split(',') : [];
    },
  },
  created() {
    request.post('/mdm/mdmRoleController/roleSelectList', {}, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
      if (res.success && Array.isArray(res.result)) {
        this.data = res.result;
      }
    });
  },
  methods: {
    inputClick() {
      if (this.disabled) return;
      this.dialogVisible = true;
    },
    closeModal() {
      this.dialogVisible = false;
      console.log(this.dialogVisible);
    },
    selectRole(value) {
      console.log(value);
      this.selectRoleList = value;
    },
    submitData() {
      this.$emit('input', this.selectRoleList);
      this.dialogVisible = false;
    },
  },
};
</script>

<style  lang = "less" scoped>
.selectRole-input{
  display: flex !important;
  .el-input-group__append {
    .el-button {
      margin: 0 -20px;
    }
  }
}
.el-input__icon{
  cursor: pointer;
}
</style>
