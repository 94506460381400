var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview" },
    [
      _c("div", { staticClass: "preview-title" }, [_vm._v("基本信息")]),
      _c("div", { staticClass: "custom-row" }, [
        _c("div", { staticClass: "custom-col" }, [
          _c("span", { staticClass: "custom-label" }, [
            _vm._v("职位级别编码:"),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.formConfig.positionLevelCode))]),
        ]),
        _c("div", { staticClass: "custom-col" }, [
          _c("span", { staticClass: "custom-label" }, [
            _vm._v("职位级别名称:"),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.formConfig.positionLevelName))]),
        ]),
        _c("div", { staticClass: "custom-col" }, [
          _c("span", { staticClass: "custom-label" }, [_vm._v("默认角色:")]),
          _c("span", [_vm._v(_vm._s(_vm.formConfig.roleName))]),
        ]),
      ]),
      _c("div", { staticClass: "preview-title" }, [_vm._v("职位信息")]),
      _c("TableForm", {
        attrs: { positionLevelCode: _vm.formConfig.positionLevelCode },
      }),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClose")
                },
              },
            },
            [_vm._v("关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }