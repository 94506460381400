<script>
import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
import configs from './config';

export default {
  extends: TablePage,
  props: ['positionLevelCode'],
  components: {
    Modal,
  },
  created() {
    this.params = { thisPositionLevelCode: this.positionLevelCode };
    this.getConfigList('position_level_user');
  },
  data() {
    return {
      configs,
      formConfig: {},
      params: null,
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      isCalculateHeight: false,
    };
  },
  methods: {

  },
};
</script>
