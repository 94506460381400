<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
import PositionView from '../positionView/positionView.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    PositionView,
  },
  data() {
    return {
      configs,
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('positionLevel_manage');
  },
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      switch (val.code) {
        case 'add':
          this.formName = 'Form';
          this.modalConfig = {
            ...this.modalConfig,
            title: '新增',
          };
          console.log(val, 'modalConfig');
          this.openModal();
          this.modalConfig.width = '600px';
          this.modalConfig.height = '300px';
          break;
        case 'edit':
          this.formName = 'Form';
          this.formConfig = {
            ...val,
            ...row,
          };
          this.modalConfig = { ...this.modalConfig, title: '编辑' };
          this.modalConfig.width = '600px';
          this.modalConfig.height = '300px';
          this.openModal();
          break;
        case 'view':
          this.formName = 'Form';
          this.formConfig = {
            ...val,
            ...row,
            code: 'view',
          };
          this.modalConfig = { ...this.modalConfig, title: '查看' };
          this.modalConfig.width = '600px';
          this.modalConfig.height = '300px';
          this.openModal();
          break;
        case 'position_view':
          this.formName = 'PositionView';
          this.modalConfig = { ...this.modalConfig, title: '职位查看' };
          this.formConfig = { ...val, ...row };
          this.modalConfig.width = '600px';
          this.modalConfig.height = '300px';
          this.openDrawer();
          break;
        default:
          break;
      }
    },
  },
};
</script>
