export default {
  formConfig: {
    searchList: [],
  },
  // toolBarConfig: [
  //   {
  //     type: 'button',
  //     buttonType: 'primary',
  //     // icon: 'el-icon-enable',
  //     code: 'add',
  //     name: '新增',
  //   },
  //   {
  //     type: 'button',
  //     buttonType: null,
  //     // icon: 'el-icon-delete',
  //     code: 'delete',
  //     name: '删除',
  //   },
  //   {
  //     type: 'button',
  //     buttonType: null,
  //     // icon: 'el-icon-enable',
  //     code: 'enable',
  //     name: '启用',
  //   },
  //   {
  //     type: 'button',
  //     buttonType: null,
  //     // icon: 'el-icon-disable',
  //     name: '禁用',
  //     code: 'disable',
  //   },
  // ],
  // columns: [
  //   {
  //     fixed: 'left', // 固定位置在左边
  //     type: 'checkbox', // 单选
  //     title: null,
  //     width: '50',
  //   },
  //   {
  //     field: 'positionLevelCode',
  //     title: '职位级别编码',
  //   },
  //   {
  //     field: 'positionLevelName',
  //     title: '职位级别',
  //   },
  //   {
  //     field: 'roleCode',
  //     title: '角色编码',
  //   },
  //   {
  //     field: 'roleName',
  //     title: '角色名称',
  //   },
  //   {
  //     field: 'enableStatus',
  //     title: '启用状态',
  //   },
  //   {
  //     title: '操作',
  //     width: '120',
  //     align: 'center',
  //     buttons: [
  //       {
  //         type: 'button',
  //         buttonType: 'primary',
  //         code: 'edit',
  //         name: '编辑',
  //         props: {
  //           type: 'primary',
  //         },
  //       },
  //       {
  //         type: 'button',
  //         buttonType: 'primary',
  //         code: 'positionView',
  //         name: '职位查看',
  //         props: {
  //           type: 'primary',
  //         },
  //       },
  //     ],
  //   },
  // ],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'functionCode', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
