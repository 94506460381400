<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import SelectRole from './components/selectRole.vue';

formCreate.component('SelectRole', SelectRole);

export default {
  extends: Form,
  components: {
    SelectRole,
    formCreate: formCreate.$form(),
  },
  create() {
  },
  data() {
    return {
    };
  },
  async created() {
    await this.getFormRule('position_level_form');
    const positionLevelCode = this.getRule('positionLevelCode');
    positionLevelCode.props = {
      ...positionLevelCode.props,
      disabled: true,
    };
    const roleCode = this.getRule('roleCode');
    roleCode.restful = '/mdm/mdmRoleController/roleSelectList';
    roleCode.headers = { functionCode: 'select-permissions-role' };
    roleCode.optionsKey = {
      label: 'roleName',
      value: 'roleCode',
    };
    roleCode.props = {
      ...roleCode.props,
      filterable: true,
      remote: true,
      remoteParams: 'roleName',
    };
    // this.rule.splice(1, 0, {
    //   type: 'SelectRole',
    //   field: 'roleCode',
    //   title: '默认角色',
    //   props: {
    //     placeholder: '请输入参数名称',
    //     disabled: false,
    //     loading: true,
    //     clearable: true,
    //   },
    //   validate: [
    //     {
    //       trigger: 'blur',
    //       required: true,
    //       message: '请选择角色',
    //     },
    //   ],
    // });
    // const enableStatus = this.getRule('enableStatus');
    // enableStatus.set = '009';
    this.setValue({
      enableStatus: '009',
    });
    this.init();
  },
  mounted() {

  },
  methods: {
    init() {
      const { id } = this.formConfig;
      request.get('/mdm/mdmPositionLevelController/queryForUpdate', { id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.setValue({
            ...result,
            roleCode: result.roleCode && result.roleCode.split(','),
          });
        }
      });
    },
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    setSelectRole() {
      console.log('form', this);
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        let url = '';
        let params = {};
        if (this.formConfig && this.formConfig.code === 'edit') {
          url = '/mdm/mdmPositionLevelController/update';
          params = { id: this.formConfig.id, positionLevelCode: this.formConfig.positionLevelCode, ...formData };
          params.roleCode = formData.roleCode ? formData.roleCode.join(',') : '';
        } else {
          url = '/mdm/mdmPositionLevelController/save';
          params = formData;
          params.roleCode = formData.roleCode ? formData.roleCode.join(',') : '';
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
